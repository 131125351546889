<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="200" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button v-show="scope.row.rev_stauts==0" @click="pass(scope.row)" type="primary" size="mini">
								确认预约
							</el-button>
							<el-button v-show="scope.row.rev_stauts==0" @click="hulue(scope.row)" type="danger" size="mini">
								忽略预约
							</el-button>
							<el-button v-show="scope.row.rev_stauts==1" type="info" size="mini" disabled>
								已确认
							</el-button>
							<el-button v-show="scope.row.rev_stauts==2" type="info" size="mini" disabled>
								已忽略
							</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '预约单位/人',
						width: ''
					},
					{
						prop: 'stageactivityvenue.name',
						name: '预约场地',
						width: '150'
					},
					{
						prop: 'stage.name',
						name: '所属驿站',
						width: '150'
					},
					{
						prop: 'date',
						name: '预约日期',
						width: ''
					},
					{
						prop: 'lx_name',
						name: '联系人',
						width: '150'
					},
					{
						prop: 'lx_phone',
						name: '联系电话',
						width: '130'
					}
				],
				tableData: [],
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			pass(row) {
				this.$confirm('请确认是否通过?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/stagebookingactivitie/submit_reservation',
						params: {
							stagebookingactivitie_id:row.id
						}
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '操作成功!'
						})
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			hulue(row){
				this.$confirm('请确认是否忽略?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/stagebookingactivitie/close_submit_reservation',
						params: {
							stagebookingactivitie_id:row.id
						}
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '操作成功!'
						})
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			addnewyz() {
				this.$router.push('/system/yun_yizhan_listadd')
			},
			showeidt(row) {
				this.$router.push({
					path: "/system/yun_yizhan_listedit",
					query: {
						id: row.id
					}
				})
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/api/stage/del',
							params: {
								ids: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});
			},
			gettable() {
				this.$post({
					url: '/api/stagebookingactivitie/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/meeting_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'yizhan_active.scss';
</style>